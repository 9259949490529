import { graphql, Link } from "gatsby"
import React from "react"
import Brushstroke from "../components/accents/Brushstroke"
import BlogTile from "../components/blog/BlogTile"
import Grid from "../components/grid/Grid"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

// PAGINATION
// https://nickymeuleman.netlify.app/blog/gatsby-pagination
// https://www.gatsbyjs.com/docs/adding-pagination/

const BlogPageTemplate = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  // destructure the object to make it more succinct
  const {
    blogPosts: { nodes: blogPosts },
  } = data

  //   only assign featured blog post a value if this is the first page of blog list
  let featuredBlogPost
  if (isFirstPage) {
    featuredBlogPost = blogPosts[0]
  }

  //   on the first page, if there is more than a single post, display the rest as tiles, on any other page, as long as there is at least one post, display them as tiles, otherwise don't display tiles
  const showTiledPosts =
    currentPage === 1 && blogPosts.length > 1
      ? true
      : currentPage > 1 && blogPosts.length > 0
      ? true
      : false

  return (
    <Layout>
      <SEO title="Blog" />
      <main>
        <Grid className="margin-bottom-default ">
          {isFirstPage ? (
            <div className="margin-top-default  col-span-default relative">
              <h1 className="z-10 relative">Art + Science Blog</h1>
              <Brushstroke className="bottom-0 left-0 right-0  w-56 md:w-96  md:-ml-24 -mr-14  -my-32  md:-mb-56  z-0 transform -rotate-35 " />
            </div>
          ) : null}
          {/* Blog posts container */}
          <div className="relative grid grid-cols-10 col-span-default grid-x-gaps">
            {/* Featured blog post */}
            {featuredBlogPost ? (
              <BlogTile
                featuredPost
                title={featuredBlogPost.title}
                image={featuredBlogPost?.featureImage?.asset?.gatsbyImageData}
                date={featuredBlogPost._createdAt}
                summary={featuredBlogPost.summary}
                to={`${featuredBlogPost.slug.current}`}
              />
            ) : null}
            {/* Remaining blog posts */}
            <div className=" col-span-full grid sm:grid-cols-2 grid-x-gaps ">
              {showTiledPosts
                ? blogPosts.map((post, index) => {
                    const isFeaturedPost =
                      isFirstPage && index === 0 ? true : false
                    {
                      /* Don't display card if it is the first post on the first page (i.e. featured post) */
                    }
                    if (!isFeaturedPost) {
                      return (
                        <BlogTile
                          title={post.title}
                          image={post?.featureImage?.asset?.gatsbyImageData}
                          date={post._createdAt}
                          summary={post.summary}
                          to={`${post.slug.current}`}
                          key={`BlogPostCard-${index}`}
                        />
                      )
                    } else {
                      return null
                    }
                  })
                : null}
            </div>
            {/* If there is more than one page, show pagination */}
            {numPages > 1 ? (
              <>
                <div className="border-t col-span-full  border-dark-grey-blue margin-top-default" />
                <div className=" mt-5  md:col-start-3 col-span-full md:col-span-6 flex  justify-between  ">
                  <div>
                    <Link
                      to={`/blog/${prevPage}`}
                      className={`text-storm-blue cursor-pointer hover:underline ${
                        isFirstPage ? "invisible" : ""
                      }`}
                    >
                      {"<"} Previous page
                    </Link>
                  </div>
                  <div className={` hidden sm:grid grid-flow-col gap-x-3`}>
                    {Array.from({ length: numPages }, (_, i) => (
                      <Link
                        key={`pagination-number${i + 1}`}
                        to={`/blog${i === 0 ? "" : `/${i + 1}`}`}
                        className="text-storm-blue  cursor-pointer hover:underline"
                        activeClassName="text-button-drop-shadow underline"
                      >
                        {i + 1}
                      </Link>
                    ))}
                  </div>

                  <div>
                    <Link
                      className={`text-storm-blue cursor-pointer hover:underline ${
                        isLastPage ? "invisible" : ""
                      }`}
                      to={`/blog/${nextPage}`}
                    >
                      Next Page {">"}
                    </Link>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </Grid>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query BlogListPageQuery($skip: Int!, $limit: Int!) {
    sampleImage: file(relativePath: { eq: "AO-007A6534c.jpg" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 700
          aspectRatio: 1.5
        )
      }
    }
    blogPosts: allSanityBlogPost(
      sort: { fields: _createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        _createdAt(formatString: "dddd, MMMM Do, YYYY")
        title
        summary
        featureImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700, aspectRatio: 1.5)
          }
        }
        slug {
          current
        }
      }
    }
  }
`

export default BlogPageTemplate
