import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const Brushstroke = ({ className }) => {
  const { brushstroke } = useStaticQuery(graphql`
    query BrushStrokeQuery {
      brushstroke: file(relativePath: { eq: "brush.png" }) {
        relativePath
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 75
            width: 432
            height: 545
            aspectRatio: 0.7
          )
        }
      }
    }
  `)
  return (
    <div className={`absolute ${className}`}>
      <GatsbyImage image={getImage(brushstroke)} alt="" />
    </div>
  )
}

export default Brushstroke
