import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

// Component can be used for feature post or standard posts with the "featuredPost" prop"

const BlogTile = ({
  featuredPost = false,
  className = "",
  date = "",
  title = "",
  summary = "",
  to = "",
  image = null,
  alt = "",
}) => {
  const data = useStaticQuery(graphql`
    query BlogTileQuery {
      defaultImage: file(
        relativePath: { eq: "logos/FullColourLogo_Border.png" }
      ) {
        relativePath
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 75, width: 700)
        }
      }
    }
  `)

  const postImage = image || getImage(data.defaultImage)

  return (
    <Link
      to={`/blog/${to}`}
      className={` bg-white rounded-2xl font-normal transform hover:scale-105 transition-transform grid ${
        featuredPost ? "lg:grid-cols-2 col-span-full" : ""
      }  grid-x-gaps overflow-hidden margin-top-default ${className}`}
    >
      <div className=" flex flex-col py-8 px-7 row-start-2 lg:row-start-auto">
        <div className="  text-brand-grey">{date}</div>
        <div className="text-2xl font-extrabold ">{title}</div>
        <div className="my-8 flex-grow">
          <p>{summary}</p>
        </div>
        <div className="font-extrabold text-storm-blue text-base hover:underline">
          Read the rest of this blog post&nbsp;&nbsp;→
        </div>
      </div>
      {/* This image works better for layout if in a containing div */}
      <div className="row-start-1 lg:row-start-auto  flex items-end">
        <GatsbyImage
          image={postImage}
          alt={alt}
          className="row-start-1 lg:row-start-auto "
          objectFit="cover"
        />
      </div>
    </Link>
  )
}

export default BlogTile
